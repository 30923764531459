import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../../styles/CpExposFormStepOne.css';
import Markdown from 'markdown-to-jsx';
import { Spinner } from 'react-bootstrap';
import { CircularProgress, Fade, IconButton, Input, TextField } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const CpExposFormStepOne = ({ onNext, onPrevious }) => {
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showCoupDeProjecteur, setShowCoupDeProjecteur] = useState(false); // État pour dérouler/fermer Coup de Projecteur
  const [showGrandesExpos, setShowGrandesExpos] = useState(false); // État pour dérouler/fermer Grandes Expos
  const [searchQuery, setSearchQuery] = useState(''); // État pour la recherche


  // Récupérer les produits via l'API lors du montage du composant
  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_SOURCE}/api/v1/cpExpos/expos/expoProducts`)
      .then(response => {
        setProducts(response.data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Erreur lors de la récupération des produits:', error);
      });
  }, []);

  // Fonction pour sélectionner un produit et afficher les détails
  const handleProductClick = (product) => {
    setSelectedProduct(product);
  };

  const handleNextClick = () => {
    if (selectedProduct) {
      onNext(selectedProduct); // Passe à l'étape 2 avec le produit sélectionné
    }
  };

  const coupDeProjecteurProducts = products.filter(product => product.productTagIds[0] === 1);
  const grandesExposProducts = products.filter(product => product.productTagIds[0] === 2);

  const handleSearchChange = (event) => {
    setShowCoupDeProjecteur(true);
    setShowGrandesExpos(true);
    setSearchQuery(event.target.value.toLowerCase());
  };

  const filteredGrandesExposProducts = grandesExposProducts.filter((product) =>
    product.name.toLowerCase().includes(searchQuery)
  );

  const filteredCoupDeProjecteurProducts = coupDeProjecteurProducts.filter((product) =>
    product.name.toLowerCase().includes(searchQuery)
  );

  return (
    loading && (
      <div className='loader-container'>
        <Fade
          in={loading}
          style={{
            transitionDelay: loading ? '800ms' : '0ms',
          }}
          unmountOnExit
        >
          <CircularProgress color="secondary" />
        </Fade>
      </div>
    )
    ||
    <div className="cp-expos-form-step-one">
      {/* Sidebar */}
      <div className="sidebar">
        <h2>Nos produits</h2>

        {/* Input de recherche */}
        <TextField
          label="Rechercher un produit"
          variant="outlined"
          size="small"
          fullWidth
          value={searchQuery}
          onChange={handleSearchChange}
          style={{ marginBottom: '1em' }}
        />

        {/* Coup de Projecteur Section */}
        <div className="product-category">
          <dl>
            <dt
              onClick={() => setShowCoupDeProjecteur(!showCoupDeProjecteur)}
              className="category-title"
            >
              Coups de projecteur {showCoupDeProjecteur ? '-' : '+'}
            </dt>
            {showCoupDeProjecteur && (
              <dd>
                <ul className="product-list">
                  {filteredCoupDeProjecteurProducts.map((product) => (
                    <li
                      key={product.id}
                      className={`product-card ${selectedProduct && selectedProduct.id === product.id ? 'active' : ''
                        }`}
                      onClick={() => handleProductClick(product)}
                    >
                      {product.name}
                    </li>
                  ))}
                </ul>
              </dd>
            )}
          </dl>
        </div>

        {/* Grandes Expos Section */}
        <div className="product-category">
          <dl>
            <dt
              onClick={() => setShowGrandesExpos(!showGrandesExpos)}
              className="category-title"
            >
              Grandes Expos {showGrandesExpos ? '-' : '+'}
            </dt>
            {showGrandesExpos && (
              <dd>
                <ul className="product-list">
                  {filteredGrandesExposProducts.map((product) => (
                    <li
                      key={product.id}
                      className={`product-card ${selectedProduct && selectedProduct.id === product.id ? 'active' : ''
                        }`}
                      onClick={() => handleProductClick(product)}
                    >
                      {product.name}
                    </li>
                  ))}
                </ul>
              </dd>
            )}
          </dl>
        </div>
      </div>

      {/* Main Content */}
      <div className="main-content">
        {selectedProduct ? (
          <>
            <div className='product-container'>
              {
                selectedProduct.productTagIds[0] === 1 &&
                (
                  <div>
                    <h2>Coup de projecteur</h2>
                    <ul>
                      <li>Disposer d’une salle de 30 m2</li>
                      <li>La salle doit être accessible, sécurisée, équipée de prises électriques et d’une connexion internet.</li>
                      <li>Identifier des professionnels pour animer l’exposition.</li>
                      <li>Garantir la présence d’un interlocuteur technique, sur le site lors du montage et du démontage de l’exposition</li>
                      <li>Réserver le Coup de Projecteur <b>5 jours ouvrés minimum</b> (hors montage et démontage)</li>
                      <li>Fournir le planning des animations</li>
                    </ul>
                    <p><b>La pré-réservation ne constitue pas une acceptation définitive de votre demande</b></p>
                  </div>
                ) || selectedProduct.productTagIds[0] === 2 &&
                (
                  <div>
                    <h2>Grande Expo</h2>
                    <ul>
                      <li>Disposer d’une salle de 150 m2 </li>
                      <li>La salle doit être accessible, sécurisée, équipée de prises électriques et d’une connexion internet.</li>
                      <li>Identifier des professionnels pour animer l’exposition.</li>
                      <li>Garantir la présence d’un interlocuteur technique, sur le site lors du montage et du démontage de l’exposition</li>
                      <li>Réserver la grande exposition <b>2 mois minimum</b></li>
                      <li>Fournir le planning des animation</li>
                    </ul>
                    <p><b>La pré-réservation ne constitue pas une acceptation définitive de votre demande</b></p>
                  </div>
                )
              }
            </div>
            <div className="product-container">
              <h2>{selectedProduct.name}</h2>
              <div className='product-details'>
                {selectedProduct.image512 && (
                  <img src={`data:image/jpeg;base64,${selectedProduct.image512}`} alt={selectedProduct.name} className="product-detail-image" />
                )}
                <div className="description-columns">
                  <Markdown>{selectedProduct.descriptionSale}</Markdown>
                </div>
              </div>
              <button id='change-step-btn-1' className="btn-change-step" onClick={handleNextClick}>Réserver ce produit</button>
            </div>
          </>
        ) : (
          <p>Sélectionnez un produit pour voir les détails.</p>
        )}
      </div>
    </div>
  );
};

export default CpExposFormStepOne;

import { MenuItem, Select } from '@mui/material';
import axios from 'axios';
import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';

const AddressSection = ({ streetTypeCatalog, newAddress, setNewAddress, errors }) => {
  const [addressesList, setAddressesList] = useState([]);
  const [manualStreetNumber, setManualStreetNumber] = useState("");
  const [showManualInput, setShowManualInput] = useState(false);
  const [showAddressDetails, setShowAddressDetails] = useState(false); // Nouvel état pour afficher le champ complément
  const [addressDetails, setAddressDetails] = useState(""); // État pour la valeur du champ complément

  const setAddressData = (address) => {
    const [nameToFind, ...streetLabelParts] = address.properties.street.split(" ");
    const matchingStreetType = streetTypeCatalog.find(
      (type) => type.name === nameToFind
    );

    const housenumber = address.properties.housenumber;
    setShowManualInput(!housenumber);
    setShowAddressDetails(true); // Afficher le champ complément d'adresse

    setNewAddress({
      ...newAddress,
      type: 3,
      contactAddressComplete: null,
      customAddressStreetNumber: housenumber || manualStreetNumber.toString(),
      customAddressStreetTypeId: matchingStreetType?.id || null,
      customAddressStreetLabel: matchingStreetType
        ? streetLabelParts.join(" ")
        : address.properties.street,
      customAddressCityInsee: address.properties.citycode,
      zip: address.properties.postcode,
      city: address.properties.city,
    });
  };

  const handleManualStreetNumberChange = (e) => {
    const value = e.target.value;
    setManualStreetNumber(value);

    setNewAddress((prevAddress) => ({
      ...prevAddress,
      customAddressStreetNumber: value,
    }));
  };

  const handleAddressDetailsChange = (e) => {
    const value = e.target.value;
    setAddressDetails(value);

    setNewAddress((prevAddress) => ({
      ...prevAddress,
      customAddressStreetDetails: value, // Mise à jour de custom_address_street_details
    }));
  };

  const handleChangeAddress = async (inputValue) => {
    const response = await axios.get(
      `https://api-adresse.data.gouv.fr/search/?q=${inputValue}`
    );
    if (response.data.features) {
      setAddressesList(response.data.features);
    }
  };

  return (
    <div className="step-three-form-open-section">
      <div className="step-three-form-section">
        <div>
          {/* Section Adresse */}
          <div className="address-section">
            <Form.Label
              style={{
                color: Object.keys(errors).length > 0 ? "red" : "",
              }}
            >
              <b>Adresse d'installation*</b>
            </Form.Label>
            {Object.keys(errors).length > 0 && (
              <p style={{ textAlign: "left", color: "red" }}>
                L'adresse sélectionnée n'est pas valide.
              </p>
            )}
            <AsyncTypeahead
              filterBy={() => true}
              labelKey={(addressesList) =>
                `${addressesList.properties.name} ${addressesList.properties.city.toUpperCase()} (${addressesList.properties.postcode})`
              }
              minLength={3}
              onSearch={handleChangeAddress}
              options={addressesList}
              emptyLabel=""
              promptText="Tapez pour chercher..."
              placeholder="Rechercher une adresse"
              onChange={(selected) => {
                if (selected && selected.length > 0) {
                  const newValue = selected[0];
                  setAddressData(newValue);
                } else {
                  setAddressesList([]);
                  setShowAddressDetails(false); // Masquer le champ complément si aucune adresse sélectionnée
                }
              }}
              inputProps={{ style: { height: "3em" } }}
            />
            {showManualInput && (
              <div style={{ marginTop: "1em" }}>
                <Form.Label>
                  <b>Numéro de rue*</b>
                </Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Entrez le numéro de rue"
                  value={manualStreetNumber}
                  onChange={handleManualStreetNumberChange}
                  required
                  style={{ height: "3em" }}
                />
              </div>
            )}
            {showAddressDetails && (
              <div style={{ marginTop: "1em" }}>
                <Form.Label>
                  <b>Complément d'adresse</b>
                </Form.Label>
                <Form.Control
                  type="text"
                  value={addressDetails}
                  onChange={handleAddressDetailsChange}
                  style={{ height: "3em" }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddressSection;

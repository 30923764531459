import React from 'react';

const OdooStructureCard = ({ odooStructure, setAdressSectionIsOpen }) => {
  return (
    <div 
    style={{ 
      cursor: 'pointer', 
      padding: '10px', 
      border: ' 2px solid #4CAF50',
      borderRadius: '5px', 
      backgroundColor:'#f9fff9' 
    }}
    className='step-three-form-section' onClick={() => setAdressSectionIsOpen(true)}>
        <p style={{textAlign: 'left'}}>Votre structure a été trouvée dans notre base de données</p>
      <h3>{odooStructure.name || "Nom de la structure non disponible"}</h3>
    </div>
  );
};

export default OdooStructureCard;
